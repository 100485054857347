exports.items={"Roll Sushi (BOGO)":[
    {
        name:"CALIFORNIA ROLL",
        price:7.50,
        desc:"Crab, cucumber, avocado, inside rice outside with sesame seeds"
    },
    {
        name:"SPICY TUNA ROLL",
        price:7.50 ,
        desc:"Tuna, spicy mayo, tempura crumb, rice with sesame seeds outside"
    },

    {
        name:"EEL ROLL",
        price:7.50, 
        desc:"Baked eel, cucumber, inside rice outside with sesame seed served with eel sauce"
    },

    {
        name:"PHILADELPHIA ROLL",
        price:7.50 ,
        desc:"Smoked salmon, cream cheese, avocado inside rice outside with sesame seeds"
    },

    {
        name:"CRUNCHY ROLL",
        price:7.50 ,
        desc:"Shrimp tempura cucumber, inside rice outside with sesame seeds served spicy mayo sauce & eel sauce"

    },

    {
        name:"TEMPURA CRAB ROLL",
        price:7.50,
        desc:" Crab tempura, cucumber inside rice outside with sesame seeds served spicy mayo & eel sauce"

    },

    {
        name:"SPICY CRAB MEAT ROLL",
        price:7.50 ,
        desc:"Crab meat, tempura crumbs, with spice mayo sauce inside rice outside with sesame seeds"
    },
    {
        name:"SPICY SALMON ROLL" ,
        price:7.50, 
        desc:"Salmon, spicy mayo, smelt roe, tempura crumb, rice outside with sesame seeds"

    },

    {
        name:"BOSTON ROLL",
        price:7.50 ,
        desc:"Shrimp, lettuce, rice outside with sesame seeds served with mayo sauce"
    },


    {
        name:"FUTO MAKI",
        price:7.50 ,
        desc:"Omelet avocado, cucumber, crabmeat with rice seaweed outside"
    }
    ,

    {
        name:"TUNA AVOCADO ROLL",
        price:7.5,
        desc:"Tuna avocado with rice outside"
    },


    {
        name:"Salmon AVOCADO ROLL",
        price:7.5,
        desc:"Tuna avocado with rice outside"
    },


    {
        name:"TUNA ROLL",
        price:6.50 ,
        desc:"Fresh tuna with rice seaweed outside"
    },
    {
        name:"SALMON ROLL",
        price:6.50,
        desc:"Fresh salmon with rice seaweed outside"
    },


    {
        name:"YELLOW TAIL ROLL",
        price:6.50 ,
        desc:"Fresh yellow tail, scallions with rice seaweed outside"
    },


    {
        name:"KAPPA CUCUMBER ROLL",
        price:5.50    ,
        desc:"Cucumber, sesame seeds with rice seaweed outside"
    },


    {
        name:"AVOCADO ROLL",
        price:5.50 ,
        desc:"Avocado, sesame seeds with rice seaweed outside"
    },

    {
        name:"SALMON SKIN ROLL",
        price:6.50,
        desc:"Baked salmon skin cucumber, rice outside, sever eel sauce"
    },

    {
        name:"ASPARAGUS ROLL",
        price:5.50,
        desc:"Asparagus, sesame seeds with rice seaweed outside"
    },

    {
        name:"VEGETABLE ROLL",
        price:6.50,
        desc:"Cucumber, asparagus, avocado inside seaweed outside"
    },
],
"Appetizers":[
    {
        name:"EDAMAME",
        price:2.95,
        desc:"Boiled soybeans"
    },
    {
        name:"GYOZA",
        price:5.25,
        desc:"Japanese Potstickers"
    },
    {
        name:"HARUMAKI",
        price:2.50,
        desc: "2 Pieces deep fried vegetable spring roll"
    },
    {
        name:"SHRIMP TEMPURA APPETIZER",
        price:7.95,
        desc:"3 Pieces shrimp and deep fried, served with tempura sauce"
    },
    {
        name:"SUSHI APPETIZER",
        price:7.25,
        desc:"Chefs choice 5 pieces sushi"
    },
    {
        name:"SASHIMI APPETIZER",
        price:8.95,
        desc:"Chefs choice of 3 kinds of fish"
    }
],
"Salad & Soup":[
    {
        name:"SEAWEED SALAD",
        price:"3.75"
    },
    {
        name:"House Green Salad",
        price:"2.95"
    },
    {
        name:"Squid Salad",
        price:"5.25"
    },
    {
        name:"Miso Soup",
        price:"1.95"
    },
    {
        name:"Onion Soup",
        price:"1.95"
    }
],
"Hibachi":[
    {
        name:"Chicken",
        price:6.50
    },
    {
        name:"Steak",
        price:7.75
    },{
        name:"Shrimp",
        price:7.75
    },
    {
        name:"Vegetable",
        price:5.50
    },
    {
        name:"Tofu",
        price:6.50
    },
    {
        name:"Chicken & Shrimp",
        price:8.25
    },
    {
        name:"Chicken & Steak",
        price:8.75
    },
    {
        name:"Shrimp & Steak",
        price:11.95
    },
    {
        name:"Chicken & Shrimp & Steak",
        price:11.95
    }
],
"Special Maki":[
  
    {
        name:"RAINBOW MAKI",
        price:10.25,
        desc:"California roll inside outside with tuna, salmon, white fish 8 pcs ",
    },
    {
        name:"DRAGON MAKI",
        price:10.25,
        desc:"Shrimp tempura inside topped with eel, avocado served eel sauce 8 pcs"
    },
    {
        name:"TEMPURA MAKI",
        price: 10.25,
        desc:"Cream cheese, crab avocado white fish battered fried served spicy mayo sauce 12 pcs"
    }
    ,
    { 
        name:"SPIDER MAKI",
        price: 11.95,
        desc: "Soft shell crab, cucumber, sesame seeds outside. Sprinkled with eel sauce, 12 pcs"
    },
    { 
        name:"SPICY GIRL",
        price:10.25,
        desc:"Spicy salmon inside topped spicy tuna with tempura crumb, masago served spicy mayo sauce 8 pcs"
    },
    {   
        name:"SALMON LOVER",
        price: 10.25,
        desc: "Salmon avocado inside with salmon on topped 8 pcs"
    },
    {
        name:"TUNA LOVER",
        price:10.25,
        desc:"Tuna cucumber inside with tuna on topped 8 pcs"
     },
     { name: "HOUSE MAKI",
       price: 10.25,
       desc:"Shrimp tempura, crab meat, avocado cucumber rice outside with sesame seeds. Served eel sauce and spicy mayo sauce 8 pcs"
     },
     {
         name:"CAROLINA MAKI",
        price: 10.25,
        desc:" Shrimp tempura, inside, spicy crab meat on topped, sprinkled with spicy mayo sauce 8 pcs" 
    }

]
,
"Sushi & Sashimi":[
    {
        name:"Tuna(Magura)",
        price:1.95
    },
    {
        name:"Salmon(SAKE)",
        price:1.95
    },

    {
        name:"Yellow Tail",
        price:1.95
    },

    {
        name:"Red Snapper",
        price:1.75
    },
    
    {
        name:"Shrimp",
        price:1.75
    },

    {
        name:"White Tuna(albacore)",
        price:1.95
    },

    {
        name:"Eel(Unagi)",
        price:1.95
    },

    {
        name:"Inar(TOFU)",
        price:1.75
    },

    {
        name:"Smoked Salmon",
        price:1.95
    },
    {
        name:"Squid",
        price:1.95
    },
    {
        name:"Red Clam",
        price:1.75
    },
    {
        name:"Omelet",
        price:1.75
    },


    {
        name:"Kani (Crab Meat)",
        price:1.75
    },

    {
        name:"Tobigo",
        price:1.75
    },

    {
        name:"Tako(Octopus)",
        price:1.95
    },
],
"Combo Plate":[
        {name:"Sushi Combo",price:15.95, desc:"Chef's choice of 7 pieces of nigir sushi and alifornia roll"},

        {name:"Sushi and Sashimi Combo",price:20.95, desc:"Chef's choice sashimi and 5 piece sushi and california roll"},

        {name:"Sashimi Combo",price:19.95, desc:"Chef's choice of 6 kinds of fish"}
    ]

    
}