import React, { useState } from 'react';
import './index.css'
  
const items=require("./menu.js").items

function Header(){
  return (
    <header>
      <div className="icon"></div>
      <div className="title">SUSHI &#38; HIBACHI TOGO
      <div className="small">
        <a href="#location">LOCATION	&#38; HOURS</a> 
        <a href="#menu">MENU</a>
      </div>
      </div>
    </header>
  );
}


function Slider(props){
  return (
    <div className="background">
      {props.children}
    </div>
  );
}
function MenuTabs(props){
  const [display, setDisplay] = useState(0);
  let productDesc=[];
  if(display){
    let sortedProducts=props.products.slice()
    sortedProducts.sort(function( a, b ) {
      if ( a.name < b.name ){
        return -1;
      }
      if ( a.name > b.name ){
        return 1;
      }
      return 0;
    } )
    sortedProducts.forEach((product)=>{
      productDesc.push(
      <div className="menuitem">
        <b>{product.name}</b> ${product.price}
        <br/> <p>{product.desc}</p>
      </div>)
    });
  }
  return [<div className="menutab" onClick={()=>{
    setDisplay(!display)
  }}>{props.title}<div class="arrowdown"></div></div>,<div className="menuitemwrapper">{productDesc}</div>]
}


function Menu(props){
  const items=Object.keys(props.items).sort().map((item)=>{
    return <MenuTabs title={item} products={props.items[item]}/>
  })
  return (
    <div className="menu" id="menu">
      <h2>Menu</h2>
      {items}
    </div>
  );
}

function Location(){
  return (
    <div className="location" id="location">
      <h2>Sushi Hibachi Togo</h2>
      <div className="wrapper">
        <div className="hour">
          <b><u>Business Hours</u></b><br/>
          <p>
          <b>Mon. - Thurs: </b> <small>10:30am - 9:00 pm</small><br/><br/>
          <b>Fri.- Sat: </b> <small>10:30am - 10:00 pm</small><br/><br/>
          <b>Sunday: </b> <small>Closed</small>
          </p>
          <b><u>Location</u></b><br/>
          <p>
          <b>1100 B Elmwood Ave, Columbia SC 29201</b>
          </p>
          <b><u>Phone</u></b><br/>
          <p>
          <b>803 - 400 - 0988</b>
          </p>
        </div>
        <iframe src="https://www.google.com/maps/embed?pb=!1m26!1m12!1m3!1d3307.155649418485!2d-81.043465734786!3d34.01421543061628!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m11!3e6!4m3!3m2!1d34.0153768!2d-81.0423422!4m5!1s0x88f8a4d04e7536c3%3A0xb6e4543240134831!2sgoogle%20map%20sushi%20hibachi%20to%20go!3m2!1d34.012709!2d-81.03988199999999!5e0!3m2!1sen!2sus!4v1608832676097!5m2!1sen!2sus" width="600" height="450" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
      </div>
    </div>
  );
}

function Footer(){
  return (
    <footer>
      Sushi Hibachi Togo © 2023  
      <br/><small>Site by <a href="http://tommydong.com">Tommy Dong</a></small> 
    </footer>
  );
}

function App() {

  return (
    <div className="App">
      <Slider>
        <Header/>
      </Slider>
      <Menu items={items}></Menu>
      <Location></Location>
      <Footer/>
    </div>
  );
}


export default App;
